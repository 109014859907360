import { Editor } from '@tinymce/tinymce-react';
import { getConfig } from '../../utils/config';
import initEditorConfiguration from './configuration/configuration';
import './editor-style.css';
import { HaEditorComponentProps } from './ha-editor.component.types';
const { EDITOR_API_KEY } = getConfig();

const HaEditorComponent = ({ tinymceEditoryRef }: HaEditorComponentProps) => {
  console.log(EDITOR_API_KEY);
  return (
    <Editor
      apiKey={EDITOR_API_KEY}
      onInit={(evt, editor) => (tinymceEditoryRef.current = editor)}
      init={initEditorConfiguration as any}
    />
  );
};

export default HaEditorComponent;
